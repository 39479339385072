/**
 * Remove slashes from the start and end of a string
 * @param {string} str
 * @return {string}
 */
function stripSlashes(str) {
	return str.replace(/(^\/+|\/+$)/g, '');
}

export default path => {
	[...document.querySelectorAll('a.is-active, a.is-current')].forEach(link => {
		link.classList.remove('is-current');
	});
	[...document.querySelectorAll('a')].forEach(link => {
		if (stripSlashes(link.pathname) === stripSlashes(path)) {
			link.classList.add('is-current');

			// Activate parent link
			const linkParentLi = link.closest('[data-primary]');
			if (!linkParentLi) return;
			const linkParent = linkParentLi.querySelector('[data-parent]');
			if (!linkParent) return;
			linkParent.classList.add('is-current');
		}
	});
};
